import { createAsyncThunk } from '@reduxjs/toolkit';
import { callGetAPI, callPostAPI, callPutAPI } from '../../services/auth';

export const getHistory = createAsyncThunk(
  'getHistory',
  async (params, { rejectWithValue }) => {
    try {
      const response = await callGetAPI({
        route: 'history-of-estimates',
        params,
      });
      return { response };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getHistoryDetail = createAsyncThunk(
  'getHistoryDetail',
  async (params, { rejectWithValue }) => {
    try {
      const { requestId, ...newParams } = params;
      const response = await callGetAPI({
        route: 'history-of-calculation/' + requestId,
        params: newParams,
      });
      return { response };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateRequestStatus = createAsyncThunk(
  'updateRequestStatus',
  async (params, { rejectWithValue }) => {
    try {
      const response = await callPostAPI({
        route: 'update-trip-request-status',
        body: params,
      });
      return { response };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateRequestLanguageDetailPage = createAsyncThunk(
  'updateRequestLanguageDetailPage',
  async (params, { rejectWithValue }) => {
    try {
      const response = await callPostAPI({
        route: 'update-trip-request-language',
        body: params,
      });
      return { response };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
export const updateManualAditionalDetailPage = createAsyncThunk(
  'updateManualAditionalDetailPage',
  async (params, { rejectWithValue }) => {
    try {
      const response = await callPostAPI({
        route: 'update-request-quote',
        body: params,
      });
      return { response };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const sendOffer = createAsyncThunk(
  'sendOffer',
  async (params, { rejectWithValue }) => {
    try {
      const response = await callPostAPI({ route: 'send-offer', body: params });
      return { response };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const customerNotify = createAsyncThunk(
  'customerNotify',
  async (params, { rejectWithValue }) => {
    try {
      const response = await callPostAPI({
        route: 'notify-client',
        body: params,
      });
      return { response };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getOfferDetail = createAsyncThunk(
  'getOfferDetail',
  async (params, { rejectWithValue }) => {
    try {
      const response = await callPostAPI({
        route: 'offer-details',
        body: params,
      });
      return { response };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const sendOfferToSupplier = createAsyncThunk(
  'sendOfferToSupplier',
  async (params, { rejectWithValue }) => {
    try {
      const response = await callPostAPI({
        route: 'send-tripdetails-to-suppliers',
        body: params,
      });
      return { response };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const submitSupplierOffer = createAsyncThunk(
  'submitSupplierOffer',
  async (params, { rejectWithValue }) => {
    try {
      const response = await callPostAPI({
        route: 'send-offer-to-buspartner',
        body: params,
      });
      return { response };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateCustomerTripRequestEmail = createAsyncThunk(
  'updateCustomerTripRequestEmail',
  async ({ pathParams, body }, { rejectWithValue }) => {
    try {
      await callPutAPI({
        route: `trip-requests/${pathParams.tripCode}/custom-email`,
        body,
      });
      return { custom_html_email: body.custom_html_email };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
